

























import Vue from 'vue'
import { ContentTree } from '../types'
import createComponent from '@simpl/cms-components/utils/create-component'
import { MUTATIONS } from '../store/consts'
import { scheduleUpdateQueue } from '@simpl/cms-components/utils/update-queue'

export default Vue.extend({
  name: 'CMSEditRenderer',

  provide () {
    const provideData = {}

    Object.defineProperty(provideData, 'rootContent', {
      enumerable: true,
      get: () => this.content
    })

    Object.defineProperty(provideData, 'editMode', {
      enumerable: true,
      get: () => this.editMode
    })

    return provideData
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode || this.$store.state.cms.translateMode
    },
    content (): ContentTree {
      const root: ContentTree = this.$store.getters['cms/selectedContent']

      if (!root) return null!

      if (!root?.data) {
        Vue.set(root, 'data', {})
      }
      if (!root?.data?.tag) {
        root.data!.tag = 'CBody'
        scheduleUpdateQueue()
      }
      return this.$store.getters['cms/selectedContent']
    }
  },

  created () {
    this.$store.commit(`cms/${MUTATIONS.RESET_COMPONENT_MAPPING}`)
  },

  methods: {
    addEmptySection () {
      const newComponent = createComponent(
        this,
        this.content,
        'CSection'
      )

      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, newComponent.id)
    }
  }
}
)
