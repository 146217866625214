
























































































import Vue from 'vue'
import IFrameWrapper from '../components/IFrameWrapper'
import CMSEditRenderer from '../components/CMSEditRenderer.vue'
import CMSRenderer from '../components/CMSRenderer.vue'
import StateCMS from '../store/types'
import { ContentTree, IAdapterCMS } from '../types'
import VoidAdapter from '@simpl/cms-components/adapters/VoidAdapter'
import { debounce } from '@simpl/core/utils'
import store from '@simpl/core/plugins/store'
import { MUTATIONS } from '../store/consts'
import GenericDeviceSvg from '../components/svg/GenericDeviceSvg.vue'

export default Vue.extend({
  name: 'CMSEditRenderWrapperView',

  components: {
    CMSEditRenderer,
    CMSRenderer,
    IFrameWrapper,
    GenericDeviceSvg
  },

  data () {
    return {
      adapter: null! as IAdapterCMS,
      rootContainerHeight: null as null | number,
      deviceFrame: 14,
      borderOffset: 4,
      isDragActive: false,
      isInitialDrag: true
    }
  },

  computed: {
    root (): ContentTree {
      return this.$store.getters['cms/selectedContent']
    },
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    device (): Record<string, any> {
      const { device, orientation } = this.$store.state.cms as StateCMS
      if (!device) return {}

      if (device.fixed || orientation === 'portrait') return device

      // Flip width/height
      return {
        ...device,
        width: device.height || undefined,
        height: device.width || undefined
      }
    },
    deviceBorderRadius (): number {
      const borderRadius = this.device.borderRadius ? this.device.borderRadius : 0
      return borderRadius * this.$store.state.cms.zoom
    },
    deviceMaxWidth (): any {
      return this.device.width || !this.$store.state.auth?.domain?.properties?.cmsMaxWidth ? undefined : this.$store.state.auth.domain.properties.cmsMaxWidth
    },
    needsOverflowFix (): boolean {
      return this.rootContainerHeight
        ? this.rootContainerHeight < this.device.height * this.$store.state.cms.zoom
        : false
    },
    isResponsive (): boolean {
      return !this.device.width && !this.device.height
    },
    zoomedResponsive (): boolean {
      return this.isResponsive && this.$store.state.cms.zoom > 1
    },
    showGenericDevice (): boolean {
      return this.device.background === 'genericDevice'
    },
    dark (): boolean {
      return this.$store.state.cms.dark
    },
    draggedFilesCount: {
      get (): number {
        return this.$store.state.cms?._dragDropFileCount
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_COUNT}`, v)
      }
    }
  },

  watch: {
    draggedFilesCount (v) {
      if (v > 0) return

      this.isInitialDrag = true
      this.isDragActive = false
    }
  },

  async created () {
    this.adapter = new VoidAdapter()
    await this.adapter.init(null, null)
  },
  mounted () {
    this.updateRootContainerHeight()

    window.addEventListener('resize', this.updateRootContainerHeight)

    window.setTimeout(() => {
      const iframe = document.querySelector('iframe')!

      window.addEventListener('dragenter', this.onDragEnter)
      iframe.contentWindow!.addEventListener('dragenter', this.onDragEnter)
      window.addEventListener('dragleave', this.onDragLeave)
      iframe.contentWindow!.addEventListener('dragleave', this.onDragLeave)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateRootContainerHeight)
  },

  methods: {
    updateRootContainerHeight () {
      const rootContainer = this.$refs.rootWrapper as HTMLElement
      this.rootContainerHeight = rootContainer.clientHeight
    },

    onDragEnter (e: DragEvent) {
      this.handleDragEnter()

      if (this.isInitialDrag) {
        this.isInitialDrag = false
        this.isDragActive = true

        if (!e.dataTransfer?.types.find(type => type === 'Files')) return

        this.draggedFilesCount = e.dataTransfer.items.length

        if (this.draggedFilesCount) {
          store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_TYPE}`, e.dataTransfer.items[0].type)
        }
      }
    },

    handleDragEnter: debounce(function (this: any) {
      this.isDragActive = true
    }),

    onDragLeave () {
      this.isDragActive = false
      this.onDragLeaveCleanUp()
    },

    onDragLeaveCleanUp: debounce(function (this: any) {
      if (this.isDragActive) return

      this.isInitialDrag = true
      this.draggedFilesCount = 0
      store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_TYPE}`, null)
    }, 50)
  }
})
