var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"rootWrapper",staticClass:"cms-edit-renderer--wrapper"},[_c('div',{staticClass:"cms-edit-renderer--overflow-helper",style:({
      position: 'relative',
      height: _vm.needsOverflowFix ? 'auto' : '100%'
    })},[_c('div',{staticClass:"cms-edit-renderer--position-wrapper",class:{ 'zoomed-responsive': _vm.zoomedResponsive },style:({
        position: 'relative',

        width: _vm.device.width ? undefined : ((100 * _vm.$store.state.cms.zoom) + "%"),
        height: _vm.device.height ? undefined : ((100 * _vm.$store.state.cms.zoom) + "%"),
        'max-width': _vm.deviceMaxWidth
      })},[(_vm.showGenericDevice)?_c('GenericDeviceSvg',{staticClass:"generic-device",style:({
          position: 'absolute',
          left: ((- _vm.deviceFrame/2 * _vm.$store.state.cms.zoom) + "px"),
          top: ((- _vm.deviceFrame/2 * _vm.$store.state.cms.zoom) + "px"),
          boxShadow: ("0 0 " + (20 * _vm.$store.state.cms.zoom) + "px 0 rgba(0, 0, 0, 0.75)"),
          'border-radius': (_vm.deviceBorderRadius + "px"),
          width: _vm.device.width ? (((_vm.device.width + _vm.deviceFrame) * _vm.$store.state.cms.zoom + _vm.borderOffset) + "px") : "100%",
          height: _vm.device.height ? (((_vm.device.height + _vm.deviceFrame) * _vm.$store.state.cms.zoom + _vm.borderOffset) + "px") : "100%",
          'max-width': _vm.deviceMaxWidth
        })}):_vm._e(),_c('IFrameWrapper',{staticClass:"cms-renderer--container elevation-2",class:{ responsive: _vm.isResponsive, 'zoomed-responsive': _vm.zoomedResponsive },style:({
          'border-radius': _vm.showGenericDevice ? ((_vm.deviceBorderRadius - 2) + "px") : 0,
          width: _vm.device.width ? ((_vm.device.width * _vm.$store.state.cms.zoom + _vm.borderOffset) + "px") : "100%",
          height: _vm.device.height ? ((_vm.device.height * _vm.$store.state.cms.zoom + _vm.borderOffset) + "px") : "100%",
        }),attrs:{"scrolling":"no"}},[_c('v-app',{style:({
            transform: ("scale(" + (_vm.$store.state.cms.zoom) + ")"),
            transformOrigin: 'left top',
            width: _vm.device.width ? ((_vm.device.width) + "px") : ((100 / _vm.$store.state.cms.zoom) + "%"),
            height: _vm.device.height ? ((_vm.device.height) + "px") : ((100 / _vm.$store.state.cms.zoom) + "%")
          }),attrs:{"dark":_vm.dark,"light":!_vm.dark}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.editMode || _vm.translateMode)?_c('CMSEditRenderer',{ref:"renderer",staticClass:"fill-height",style:({
                padding: (_vm.deviceBorderRadius + "px")
              })}):_c('CMSRenderer',{staticClass:"fill-height",style:({
                padding: (_vm.deviceBorderRadius + "px")
              }),attrs:{"root":_vm.root,"adapter":_vm.adapter}})],1)],1)],1)],1)]),(_vm.$store.state.cms._showPointerBlock)?_c('div',{staticClass:"pointer-block"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }