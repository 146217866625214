






































import Vue from 'vue'
import { ACTIONS, MUTATIONS } from '../store/consts'
import { MUTATIONS as AUTH_MUTATIONS } from '@simpl/auth/store/consts'
import { ContentTree } from '../types'
import { CONTENT_TREE_UPDATED, GET_MODULE_TEXTS_ONLY } from '../graphql'

import Sidebar from '../components/sidebar'
import CMSEditRenderWrapperView from './CMSEditRenderWrapperView.vue'
import ToolView from '../components/toolview'
import AssetUploader from '../components/AssetUploader.vue'
import { getTextForLanguage } from '@simpl/core/utils'
import { mapMutations } from 'vuex'
import { Query } from '@simpl/core/types/graphql'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

export default Vue.extend({
  name: 'CMSEditView',

  components: {
    Sidebar,
    ToolView,
    CMSEditRenderWrapperView,
    AssetUploader
  },

  props: {
    moduleId: [String, Number]
  },

  data () {
    return {
      contentUpdating: false,
      module: null! as any,
      guidedTours: [{
        name: this.$t('core.guidedTour.cms.bigTourHeadline'),
        description: this.$t('core.guidedTour.cms.bigTourDescription'),
        icon: 'mdi-brightness-1',
        steps: [{
          selector: '.tour-step-one',
          headline: this.$t('core.guidedTour.cms.sidebarToolsHeadline'),
          text: this.$t('core.guidedTour.cms.sidebarTools'),
          position: 'right'
        }, {
          selector: '.tour-step-two',
          headline: this.$t('core.guidedTour.cms.propertyEditorHeadline'),
          text: this.$t('core.guidedTour.cms.propertyEditor'),
          position: 'left'
        }, {
          selector: '.tour-step-three',
          headline: this.$t('core.guidedTour.cms.savedChangesCheckmarkHeadline'),
          text: this.$t('core.guidedTour.cms.savedChangesCheckmark'),
          position: 'left'
        }, {
          selector: '.tour-step-four',
          headline: this.$t('core.guidedTour.cms.deviceToolbarHeadline'),
          text: this.$t('core.guidedTour.cms.deviceToolbar'),
          position: 'bottom'
        }]
      }, {
        name: this.$t('core.guidedTour.cms.smallTourHeadline'),
        description: this.$t('core.guidedTour.cms.smallTourDescription'),
        icon: 'mdi-brightness-2',
        steps: [{
          selector: '.tour-step-one',
          headline: this.$t('core.guidedTour.cms.sidebarToolsHeadline'),
          text: this.$t('core.guidedTour.cms.sidebarTools'),
          position: 'right'
        }, {
          selector: '.tour-step-two',
          headline: this.$t('core.guidedTour.cms.propertyEditorHeadline'),
          text: this.$t('core.guidedTour.cms.propertyEditor'),
          position: 'left'
        }]
      }]
    }
  },

  breadcrumbs (): any[] {
    const breadcrumbs = []

    if (this.module) {
      breadcrumbs.unshift({
        title: getTextForLanguageWithFallback(this.module, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode),
        to: null
      })
    }

    return breadcrumbs
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    loading: {
      get (): number {
        return this.$store.state.cms._loading
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_LOADING_VALUE}`, v)
      }
    },
    content (): ContentTree {
      return this.$store.state.cms.content
    },
    firstSiteId (): string | null {
      const chapters = this.content.children
      for (let i = 0; i < chapters.length; i++) {
        const chapter = chapters[i]
        if (chapter.children?.length) {
          const site = chapter.children.find((child: ContentTree) => child.type === 'site')
          if (site) {
            return site.id
          }
        }
      }
      return null
    },
    showAssetUploader (): boolean {
      return this.$store.state.cms._dragDropFileCount > 1 && !this.$store.state.cms.assetPanelOpen
    }
  },

  watch: {
    '$vuetify.theme.currentTheme': {
      handler () {
        this.updateEditorStyles()
      },
      deep: true
    }
  },

  async created () {
    const moduleChanged = (this.moduleId !== this.$store.state.cms.moduleId)

    this.$store.commit(`cms/${MUTATIONS.SET_MODULE_ID}`, this.moduleId)

    this.$store.commit(`cms/${MUTATIONS.SET_EDIT_MODE}`, this.$route.params.mode === 'edit')
    this.$store.commit(`cms/${MUTATIONS.SET_TRANSLATE_MODE}`, this.$route.params.mode === 'translate')

    this.$store.commit(`cms/${MUTATIONS.SET_MASTER_LANGUAGE_CODE}`, this.module ? this.module.languagecode : this.$store.state.auth.user.languagecode)
    this.$store.commit(`cms/${MUTATIONS.SET_CURRENT_LANGUAGE_CODE}`, this.$route.params.lc || this.$store.state.auth.user.languagecode)
    this.$store.commit(`auth/${AUTH_MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: this.$route.params.lc || this.$store.state.auth.user.languagecode })

    this.contentUpdating = true

    if (!this.content || moduleChanged) {
      await this.$store.dispatch(`cms/${ACTIONS.FETCH_CONTENT}`)
      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, this.firstSiteId)
    } else {
      await this.$store.dispatch(`cms/${ACTIONS.FETCH_AND_PATCH_CONTENT}`)
    }

    this.contentUpdating = false

    if (localStorage.getItem('externalDefinedComponentId')) {
      const componentId = parseInt(localStorage.getItem('externalDefinedComponentId')!)
      localStorage.removeItem('externalDefinedComponentId')
      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, componentId)
    }
    if (localStorage.getItem('externalDefinedSiteId')) {
      const siteId = parseInt(localStorage.getItem('externalDefinedSiteId')!)
      localStorage.removeItem('externalDefinedSiteId')
      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, siteId)
    } else if (!this.$store.state.cms.selectedSiteId) {
      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, this.firstSiteId)
    }
  },
  mounted () {
    this.updateEditorStyles()

    this.setGuidedTour({
      id: 'cms',
      tours: this.guidedTours
    })
  },
  beforeDestroy () {
    this.setGuidedTour()

    const styleEl = (this as any)._editorStyleEl
    styleEl?.parentElement?.removeChild(styleEl)
    delete (this as any)._editorStyleEl
  },

  methods: {
    ...mapMutations(['setGuidedTour']),

    updateEditorStyles () {
      const primaryColor = this.$vuetify.theme.currentTheme.primary as string
      const isDark = this.$vuetify.theme.isDark
      const computedStyle = window.getComputedStyle(document.getElementById('app')!)
      const textColor = computedStyle.color
      const fontFamily = computedStyle.fontFamily

      if (!(this as any)._editorStyleEl) {
        const el = (this as any)._editorStyleEl = document.createElement('style')
        el.setAttribute('type', 'text/css')
        this.$el.ownerDocument!.head.appendChild(el)
      }
      const styleEl = (this as any)._editorStyleEl
      styleEl.innerHTML = `.cms-edit--wrapper:after {
  border-color: ${primaryColor};
}

.cms-edit--wrapper:before {
  background-color: ${primaryColor};
  color: ${isDark ? 'black' : 'white'};
}

.drop-target {
  border-color: ${primaryColor};
}
.drop-target:after {
  background-color: ${primaryColor};
  color: ${isDark ? 'black' : 'white'};
  font-family: ${fontFamily};
}

.drop-marker {
  background-color: ${primaryColor};
  color: ${primaryColor}
}`
    },
    resetDrop () {
      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_COUNT}`, 0)
      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_TYPE}`, null)
      this.$notification.publish('bottom', {
        message: this.$t('cms.message.noDropZone'),
        type: 'error',
        color: 'error'
      })
    }
  },

  apollo: {
    module: {
      query: GET_MODULE_TEXTS_ONLY,
      variables () {
        return {
          id: this.moduleId
        }
      },

      update (result: Query) {
        if (result.module?.type !== 'simpl' && result.module?.type !== 'test') {
          this.$router.replace('/404')
          return null
        }
        this.$store.commit(`cms/${MUTATIONS.SET_MASTER_LANGUAGE_CODE}`, result.module.languagecode)
        this.$store.commit(`cms/${MUTATIONS.SET_TEST}`, result.module.type === 'test')

        return result.module
      }
    },
    $subscribe: {
      contentUpdated: {
        query: CONTENT_TREE_UPDATED,

        variables () {
          return {
            module_id: this.moduleId,
            user_id: this.$store.state.auth.user.id
          }
        },

        result (result: any) {
          if (!result?.data?.contentTreeUpdated) {
            return
          }

          this.$store.commit(`cms/${MUTATIONS.PATCH_CONTENT}`, result.data?.contentTreeUpdated)
        }
      }
    }
  }
})
