<template>
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 407.2888 864"
    preserveAspectRatio="none"
    class="generic-device"
  >
    <defs>
      <symbol
        id="deviceframe"
        data-name="deviceframe"
        viewBox="0 0 407.289 864"
      >
        <rect
          class="cls-1"
          width="407.2887"
          height="863.9999"
          rx="11.7701"
        />
        <path
          class="cls-2"
          d="M402.8278,2.5581a11.6077,11.6077,0,0,1,.461,3.2129V846.3344a11.7707,11.7707,0,0,1-11.8223,11.7183H7.8222A11.8284,11.8284,0,0,1,.461,855.4945,11.8038,11.8038,0,0,0,11.8222,864H395.4665a11.7706,11.7706,0,0,0,11.8223-11.7183V11.7183A11.6521,11.6521,0,0,0,402.8278,2.5581Z"
        />
        <path
          class="cls-3"
          d="M4,858.2289V17.6656A11.7706,11.7706,0,0,1,15.8222,5.9473H399.4665a11.8292,11.8292,0,0,1,7.3613,2.5581A11.804,11.804,0,0,0,395.4665,0H11.8222A11.7706,11.7706,0,0,0,0,11.7183V852.2816a11.6518,11.6518,0,0,0,4.461,9.16A11.6039,11.6039,0,0,1,4,858.2289Z"
        />
      </symbol>
    </defs>
    <use
      width="407.2888"
      height="864"
      transform="translate(0 0)"
      xlink:href="#deviceframe"
    />
  </svg>
</template>

<script>
  export default {
    name: 'GenericDeviceSvg'
  }
</script>

<style lang="scss">
.generic-device {
  /* Scoped CSS here */
  .cls-1 {
    fill:#252628;
  }
  .cls-2 {
    fill:#1f2021;
  }
  .cls-3 {
    fill:#353638;
  }
}
</style>
