<template>
  <div>
    CMS LIST VIEW
  </div>
</template>

<script>
  import { MUTATIONS } from '../store/consts'

  export default {
    name: 'CMSListView',

    created () {
      this.$store.commit(`cms/${MUTATIONS.SET_EDIT_MODE}`, false)
    }
  }

</script>

<style scoped>

</style>
